// extracted by mini-css-extract-plugin
export var Articles = "style-module--Articles--18519";
export var ArticlesInner = "style-module--ArticlesInner--54ac2";
export var ArticlesTitle = "style-module--ArticlesTitle--65664";
export var Freebie = "style-module--Freebie--20a29";
export var FreebieImages = "style-module--FreebieImages--5f65a";
export var FreebieInner = "style-module--FreebieInner--d91fe";
export var Freebies = "style-module--Freebies--c8839";
export var FreebiesInner = "style-module--FreebiesInner--6f062";
export var FreebiesSide = "style-module--FreebiesSide--c96e8";
export var Icon = "style-module--Icon--91943";
export var Icons = "style-module--Icons--b7f9c";