import { Link } from 'gatsby';
import React from 'react';
import * as styles from './style.module.scss';
import articlesImg from '../../../assets/IMG/freebies/articles.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Articles as ArticlesList } from '../../../Data/Articles';
import { Article } from '../../../Classes/Article';

type props = {
  onAbout?: boolean;
}

const Articles: React.FC<props> = ({ onAbout }) => {
  return (
    <>
      <div className={styles.Freebies}>
        <div className={styles.FreebiesInner}>
          <h1>Read Some of Our Articles {onAbout ? <span><Link to={`/Articles`}>View All <i><FontAwesomeIcon icon={faArrowRight} /></i></Link></span> : <></>}</h1>

          <div className={styles.Articles}>
            <div className={styles.ArticlesTitle} style={{ backgroundImage: `url(${articlesImg})` }}>
              <p>
                Our expertly written articles are here to help you understand some of the key things that might affect your business. Every article written from our own experiences assisting companies across the UK.
              </p>
            </div>

            <div className={styles.ArticlesInner}>
              {
                ArticlesList.filter((a, ind) => ind < 3).map((article: Article) => {
                  return <Link to={article.Route} className={styles.Freebie}>
                    <div className={styles.FreebieImages}>
                      <img loading={"lazy"} src={article.Image} />
                    </div>
                    <div className={styles.FreebieInner}>
                      <i>
                        <FontAwesomeIcon icon={faArrowRight} />
                      </i>

                      <h2>{article.Title}</h2>
                      <h3>{article.SubTitle}</h3>
                      <p>{article.Description}</p>
                    </div>
                  </Link>
                })
              }
            </div>
          </div>

          {
            onAbout ? <></> : ArticlesList.filter((a, ind) => ind >= 3).map((article: Article) => {
              return <Link to={article.Route} className={styles.Freebie}>
                <div className={styles.FreebieImages}>
                  <img loading={"lazy"} src={article.Image} />
                </div>
                <div className={styles.FreebieInner}>
                  <i>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </i>

                  <h2>{article.Title}</h2>
                  <h3>{article.SubTitle}</h3>
                  <p>{article.Description}</p>
                </div>
              </Link>
            })
          }
        </div>
      </div>
    </>
  );
};

export default Articles;
